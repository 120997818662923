import React from "react"
import SEO from "../components/seo"
import LatticeLayout from "../components/lattice-layout"

export default function Home() {
  return (
    
    <div>
      <LatticeLayout>
      <SEO title="Lattice"/>

      
      
      </LatticeLayout>
    </div>
  );
}
